import React, { useEffect, useState } from 'react';
import { Card, CardContent,Box, Grid, IconButton, Typography, TextField, FormControl, FormLabel, FormGroup, FormHelperText } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CircleIcon from '@mui/icons-material/Circle';
import { useSelector } from 'react-redux';

const PersonalDetailsCard = ({data={},otherData={}}) => {
  const [isEditable, setIsEditable] = useState(false);
  
  const [details, setDetails] = useState({
   
    age:24,
    gender:"female",
    height: '5ft 4in',
    motherTongue: 'Hindi / Urdu',
    caste: 'Sunni / Ansari',
    disability: 'No',
    maritalStatus:"Never Married",
   
    ...data,
    ...otherData
  });


  console.log("youdjdjdjdj",data)

  const handleEditToggle = () => {
    setIsEditable(!isEditable);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  console.log("Basic info",data)
  useEffect(()=>{
    setDetails({...data,...otherData})
  },[data])

  return (
    <Card sx={{maxWidth: 900,width:{xs:"95%"}, mx: 'auto', mt: 3, p: 3, borderRadius: 2, boxShadow: 3 }}>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Personal Details</Typography>
        </Grid>

        <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={6} md={3}>
        <Box
          sx={{
            width: "100%",
            height: "auto",
            minHeight: "100px",
            maxHeight: "800px",
            borderRadius: 2,
            border: "1px solid gray",
            marginTop: 1,
            marginLeft: 1,
            position: "relative", // Allows positioning of the child elements
            overflow: "hidden", // Ensures content stays within the box boundaries
          }}
        >
          {/* Blurred Background */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `url(${details.profileImage?details.profileImage:  "https://tse2.mm.bing.net/th?id=OIP.h4fL9hpeXOvEaZ0v1h9FAQAAAA&pid=Api&P=0&h=180"})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "blur(8px)", // Blur effect for the background
              zIndex: 1, // Lower z-index so it's behind the image
            }}
          />

          {/* Main Image */}
          <Box
            component="img"
            src={details.profileImage?details.profileImage:  "https://tse2.mm.bing.net/th?id=OIP.h4fL9hpeXOvEaZ0v1h9FAQAAAA&pid=Api&P=0&h=180"}
            alt="Profile"
            sx={{
              width: "100%", // Full width of the container
              height: "100%", // Full height of the container
              objectFit: "contain", // Maintain aspect ratio and ensure the image fits within the box
              borderRadius: 2,
              border: "1px solid transparent",
              position: "relative", // Position above the background
              zIndex: 2, // Higher z-index so it's above the background
            }}
          />
        </Box>
        </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>

          <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Name"
                  name="name"
                  value={details.name}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Name: {details.firstName+" "+details.lastName}</Typography>
              )}
            </Grid>


            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Age"
                  name="age"
                  value={details.age}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Age: {details.age}</Typography>
              )}
            </Grid>



            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Gender"
                  name="gender"
                  value={details.gender}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1"><FormControl component="fieldset">
                
                </FormControl>Gender:{details.gender?.toLowerCase()}</Typography>
              )}
            </Grid>

            



            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Height"
                  name="height"
                  value={details.height}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Height: {details.height}</Typography>
              )}
            </Grid>
            
          </Grid>

          <Grid item xs={12} sm={6}>

          <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="maritalStatus"
                  name="maritalStatus"
                  value={details.maritalStatus}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Marital Status: {details.maritalStatus}</Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  label="Caste"
                  name="caste"
                  value={details.caste}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Caste: {details.religion}</Typography>
              )}
            </Grid>
            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  label="Any Disability"
                  name="disability"
                  value={details.disability}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Any Disability: {details.disability?"Yes":"No"}</Typography>
              )}
            </Grid>
            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  label="Mother Tongue"
                  name="motherTongue"
                  value={details.motherTongue}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Mother Tongue: {details.motherTongue}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PersonalDetailsCard;
