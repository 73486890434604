import axiosInstance from "../utils/requestInstance";
import environment from "../enviroment";
import { toast } from "react-toastify";
const API_URL = environment.endPoint;

const user = [];

const UserProfileService = {
  async getAllUser(payload) {
    try {
      const response = await axiosInstance.get(`/user/getAll`, {
        params: payload,
      });
      let data = response.data.result;
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },


  async createBasicDetail(payload) {
    try {
      const response = await axiosInstance.post(`/user/createBasicDetail`,payload);
      let data = response.data.result;
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },


  async createFamilyDetail(payload) {
    try {
      const response = await axiosInstance.post(`/user/createFamilyDetail`,payload);
      let data = response.data.result;
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },

  async createEducationAndCarrierDetail(payload) {
    try {
      const response = await axiosInstance.post(`/user/createEducationAndCarrier`,payload);
      let data = response.data.result;
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },



  async profileViewBy(payload) {
    try {
      const response = await axiosInstance.get(`/user/profileViewByMe`, {
        params: payload,
      });
      let data = response.data.result;
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },


  async profileShortlistedByMe(payload) {
    try {
      const response = await axiosInstance.get(`/user/profileShortlistedByMe`, {
        params: payload,
      });
      let data = response.data.result;
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },
  async myInterest(payload) {
    try {
      const response = await axiosInstance.get(`/user/myInterest`, {
        params: payload,
      });
      let data = response.data.result;
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },

  async whoIsInterestedInMe(payload) {
    try {
      const response = await axiosInstance.get(`/user/whoIsInterestedInMe`, {
        params: payload,
      });
      let data = response.data.result;
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },

  async myIgnoreProfiles(payload) {
    try {
      const response = await axiosInstance.get(`/user/ignoreProfileByMe`, {
        params: {},
      });
      let data = response.data.result;
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },




  async ignoreProfile(payload,id) {
    try {
       const response = await axiosInstance.post(`user/ignoreProfile/${id}`,payload);
       let data=response.data.result;
       toast.success(data.message)
    return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },
  async expressInterest(payload,id) {
    try {
       const response = await axiosInstance.post(`user/expressInterest/${id}`,payload);
       let data=response.data.result;
       toast.success(data.message)
    return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },


  async shortlist(payload,id) {
    try {
      const response = await axiosInstance.post(`user/shortListPofile/${id}`, {
        params: payload,
      });
      let data = response.data.result;
      return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },


  async uploadImage(payload,id) {
    try {
      const response = await axiosInstance.post(`user/uploadImage`,payload);
      console.log("what response",response)
      let data = response.data.result;
      return data;
    } catch (error) {
      console.log("what error",error)
      toast.error(error.response.data.message?error.response.data.message:"Media file is Not supported");
      console.log(error);
    }
  },


  async getOtherProfileById(payload,id) {
    try {
       const response = await axiosInstance.get(`user/GetProfileById/${id}`);
    return response.data.result;
    } catch (error) {
      toast.error(error.response?.data?.message||error.response?.data?.error.message||"Internal Server Error");
      console.log(error);
    }
  },


// OTHER ACTIVITY 

async whoViewMyProfile(payload) {
  try {
    const response = await axiosInstance.get(`/user/whoViewMyProfile`, {
      params: payload,
    });
    let data = response.data.result;
    return data;
  } catch (error) {
    toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
    console.log(error);
  }
},
  
  async Create(payload) {
    try {
       const response = await axiosInstance.post(`/user/register`,payload);
       const data=response.data.result
       toast.success(data.message);
    return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },

  async getById(payload) {
    try {
       const response = await axiosInstance.get(`/user/getById/${payload}`);
    return response.data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },


  async updateById(payload,id) {
    try {
       const response = await axiosInstance.put(`/user/updateById/${id}`,payload);
       let data=response.data.result;
       toast.success(data.message)
    return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },

  async delete(id) {
    try {
       const response = await axiosInstance.delete(`/user/delete/${id}`);
       let data=response.data.result;
       toast.success(data.message)
    return data;
    } catch (error) {
      toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
      console.log(error);
    }
  },


// WoRking to Message and Communication logic


async sendMessage(id,payload) {
  try {
     const response = await axiosInstance.post(`/chat/message/${id}`,payload);
     const data=response.data.result
     toast.success(data.message);
  return data;
  } catch (error) {
    toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
    console.log(error);
  }
},


async getAllmessage(userId,chatId) {
  try {
    const response = await axiosInstance.get(`/chat/getAllmessage/${userId}`, {
      params: {chatId:chatId},
    });
    let data = response.data.result;
    return data;
  } catch (error) {
    toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
    console.log(error);
  }
},



// Payment


async createPayment(payload,id) {
  try {
    const response = await axiosInstance.post(`paymentGateWay/createPayment`,payload);
    let data = response.data.result;
    return data;
  } catch (error) {
    toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
    console.log(error);
  }
},



async verifyPayment(payload,id) {
  try {
    const response = await axiosInstance.post(`paymentGateWay/verifyPayment`,payload);
    let data = response.data.result;
    return data;
  } catch (error) {
    toast.error(error.response.data.message?error.response.data.message:"Internal Server Error");
    console.log(error);
  }
},






};

export default UserProfileService;
