import React, { useCallback, useEffect, useState } from "react";
import "./Login.css";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { LoginOutlined } from "@mui/icons-material";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";



import environment from "../../enviroment";
import { toast } from "react-toastify";


import OPTIONS from "../../constant/Option";
import axiosInstance from "../../utils/requestInstance";
import UserProfileService from "../../services/Profile.services";

const Login = () => {
  const [formData, setFormData] = useState({
    userId: "",
    higestEducation: [], // Select field, starts as an empty array
    collegeInstitute: "",

    city: "",
    state: [], // Select field, starts as an empty array
    employedIn: [], // Select field, starts as an empty array
    occupation: [], // Select field, starts as an empty array
    isLivedWithParent:"", // Boolean field, starts as null
    occupationDetail: "",
    annualIncome: [], // Select field, starts as an empty array
    country: [] // Select field, starts as an empty array
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    setFormData({ ...formData, [name]: value });
  }

  async function HandlSubmit(e) {
    try {
      e.preventDefault();
      console.log("called the submit function");
      setLoading(true);
   let response =await UserProfileService.createEducationAndCarrierDetail(formData)
      if(response.data){
        navigate("/register/familydetail");
      }
     
      setLoading(false);

      console.log("navigated run");
    
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <div className="p-0">
     <div class="container-fluid">
  <div class="row">
    <div class="col-md-6 loginleft d-none d-md-flex">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="loginlogo">
            <img src={logo} class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 align-self-start">
      <div class="row justify-content-start">
        <div class="col-lg-11">
          <div class="loginright">
            <h3>Enter Education And Carrier Details</h3>
            <form
              class="pt-2"
              onSubmit={(e) => {
                HandlSubmit(e);
              }}
            >
              <div class="row custom-row">
                {/* New fields based on the JSON object */}
                <div class="col-12 col-sm-6 mb-3">
                  <label class="form-label">Highest Education</label>
                  <select
                    class="form-control"
                    value={formData.higestEducation}
                    onChange={(e) => handleChange(e)}
                    name="higestEducation"
                    required
                  >
                    <option value="" disabled>
                      Select Highest Education
                    </option>
                    {OPTIONS.higestEducationOptions.getAllOptions().map(option => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </div>
                <div class="col-12 col-sm-6 mb-3">
                  <label class="form-label">College/Institute</label>
                  <input
                    type="text"
                    class="form-control"
                    value={formData.collegeInstitute}
                    onChange={(e) => handleChange(e)}
                    name="collegeInstitute"
                    required
                  />
                </div>
                <div class="col-12 col-sm-6 mb-3">
                  <label class="form-label">City</label>
                  <input
                    type="text"
                    class="form-control"
                    value={formData.city}
                    onChange={(e) => handleChange(e)}
                    name="city"
                    required
                  />
                </div>
                <div class="col-12 col-sm-6 mb-3">
                  <label class="form-label">State</label>
                  <select
                    class="form-control"
                    value={formData.state}
                    onChange={(e) => handleChange(e)}
                    name="state"r
                    required
                  >
                    <option value="" disabled>
                      Select State
                    </option>
                    {OPTIONS.state.getAllOptions().map((option) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </div>
                <div class="col-12 col-sm-6 mb-3">
                  <label class="form-label">Employed In</label>
                  <select
                    class="form-control"
                    value={formData.employedIn}
                    onChange={(e) => handleChange(e)}
                    name="employedIn"
                    required
                  >
                    <option value="" disabled>
                      Select Employment Status
                    </option>
                    {OPTIONS.employedInOptions.getAllOptions().map((option) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </div>
                <div class="col-12 col-sm-6 mb-3">
                  <label class="form-label">Occupation</label>
                  <select
                    class="form-control"
                    value={formData.occupation}
                    onChange={(e) => handleChange(e)}
                    name="occupation"
                    required
                  >
                    <option value="" disabled>
                      Select Occupation
                    </option>
                    {OPTIONS.occupationOptions.getAllOptions().map((option) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </div>
                <div class="col-12 col-sm-6 mb-3">
                  <label class="form-label">Live With Parent</label>
                  <select
                    class="form-control"
                    value={formData.isLivedWithParent}
                    onChange={(e) => handleChange(e)}
                    name="isLivedWithParent"
                    required
                  >
                     <option value="">select living</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div class="col-12 col-sm-6 mb-3">
                  <label class="form-label">Occupation Details</label>
                  <input
                    type="text"
                    class="form-control"
                    value={formData.occupationDetail}
                    onChange={(e) => handleChange(e)}
                    name="occupationDetail"
                    required
                  />
                </div>
                <div class="col-12 col-sm-6 mb-3">
                  <label class="form-label">Annual Income</label>
                  <select
                    class="form-control"
                    value={formData.annualIncome}
                    onChange={(e) => handleChange(e)}
                    name="annualIncome"
                    required
                  >
                    <option value="" disabled>
                      Select Annual Income
                    </option>
                    {OPTIONS.annualIncomeOptions.getAllOptionAsArray().map((option) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </div>
                <div class="col-12 col-sm-6 mb-3">
                  <label class="form-label">Country</label>
                  <select
                    class="form-control"
                    value={formData.country}
                    onChange={(e) => handleChange(e)}
                    name="country"
                    required
                  >
                    <option value="" disabled>
                      Select Country
                    </option>
                    {OPTIONS.country.getAllOption().map((option) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div class="mb-3">
                <div class="row">
                  <div class="col-12 text-center my-4">
                    <LoadingButton
                      loading={loading}
                      size="medium"
                      type="submit"
                      variant="contained"
                      startIcon={<LoginOutlined sx={{ fontSize: 1 }} />}
                    >
                      Save And Next
                    </LoadingButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  );
};

export default Login;
