import React from 'react';
import { Box, Typography, Table,Card, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper } from '@mui/material';
import axios  from 'axios';
import UserProfileService from '../../../services/Profile.services';
function PackageTable({ title, rows }) {
  return (
    <Box sx={{ flex: 1, mb: { xs: 2, md: 0 } }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>{title}</Typography>
      <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #ddd' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', fontFamily: 'Arial' }}>Title</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontFamily: 'Arial' }}>Info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontFamily: 'Roboto', color: '#555' }}>{row.label}</TableCell>
                <TableCell sx={{ fontFamily: 'Roboto', color: '#555' }}>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function MyPackageCard() {
  const currentPackage = [
    { label: 'User Package', value: 'Default' },
    { label: 'Package Price', value: '---' },
    { label: 'Package Validity', value: '---' },
    { label: 'Package Gateway', value: '----' }
  ];

  const remainingPackage = [
    { label: 'Interests Express', value: 'Unlimited' },
    { label: 'Photo Request', value: 'Limited' },
    { label: 'Remaining Messages', value: 'Nil' },
    { label: 'Remaining Contact View', value: 'Nil' }
  ];

  async function BuyPremium(amount) {
    amount=Number(amount)

    try {
      // Step 1: Create an order from the backend
      // const { data } = await axios.post('http://localhost:5000/create-order', { amount });

    var response=await UserProfileService.createPayment({amount:500})
       console.log("respos",response.data)
      var data={success:true,
        order:response.data
      }
      console.log("what order you are gettng",data)

      if (data.success) {
        const { id: order_id, amount } = data.order;

        // Step 2: Configure Razorpay options
        const options = {
          key: 'rzp_test_r5JK3GVo92vEPm', // Replace with your Razorpay Key ID
          amount: amount,
          currency: 'INR',
          name: 'Test Payment',
          description: 'Demo Razorpay Payment',
          order_id: order_id,
          handler: async function (response) {
            const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;

            // Step 3: Verify payment on the backend
            const verificationResponse = UserProfileService.verifyPayment({razorpay_order_id, razorpay_payment_id, razorpay_signature })

          },
          prefill: {
            name: 'Your Name',
            email: 'your-email@example.com',
            contact: '9999999999',
          },
          theme: {
            color: '#3399cc',
          },
        };

        // Step 4: Open Razorpay payment interface
        const rzp = new window.Razorpay(options);
        rzp.open();
      }
    } catch (error) {
      console.error('Error initiating payment:', error);
    }
    
  }


  

  return (
    <Card sx={{ maxWidth: 900, mx: 'auto', mt: 3, p: 2, borderRadius: 2, boxShadow: 3 }}>
    
      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>My Package</Typography>

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3 }}>
        <PackageTable title="YOUR CURRENT PACKAGE" rows={currentPackage} />
        <PackageTable title="REMAINING PACKAGE" rows={remainingPackage} />
      </Box>

      <Button
      onClick={()=>{BuyPremium(500)}}
        variant="contained"
        color='success'
        
        sx={{
          mt: 3,
          // bgcolor: 'green',
          // color: 'white',
          fontWeight: 'bold',
          width: '100%',
          ':hover': {
            bgcolor: 'darkgreen'
          }
        }}
      >
        Buy Premium Plans Only ₹ 500
      </Button>
      </Card>
  );
}

export default MyPackageCard;
