import React, { useCallback, useEffect, useState } from "react";
import "./Login.css";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { LoginOutlined } from "@mui/icons-material";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addCurrentUser } from "../../Component/redux/astroSlice";

import { useSocket } from "../../useSocket.js/socket";
import environment from "../../enviroment";
import { toast } from "react-toastify";

import getLocation from "../../utils/Location.Tracker";
import OPTIONS, { genders } from "../../constant/Option";

const Login = () => {
  const [formData, setFormData] = useState({
    behalfOf: "",
    gender:"",
    
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    setFormData({ ...formData, [name]: value });
  }

  async function HandlSubmit(e) {
    try {
      e.preventDefault();
      console.log("called the submit function");
      setLoading(true);
      const response = await axios.post(
        `${environment.endPoint}/user/register`,
        formData
      );

      console.log("your response",response.data.data)

        localStorage.setItem("user", JSON.stringify(response.data.data.user));
    
      let token = response.data?.data?.token;
      localStorage.setItem("token", token);

      if(response.data.data.token){
        navigate("/register/BasicDetail");
      }

      setLoading(false);

      console.log("navigated run");
    
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <div className="p-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 loginleft d-none d-md-flex">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <div class="loginlogo">
                  <img src={logo} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 align-self-start ">
            <div class="row justify-content-start ">
              <div class="col-lg-11">
                <div class="loginright">
                  <h3>Enter Primary Details</h3>
                  <form
                    class="pt-2"
                    onSubmit={(e) => {
                      HandlSubmit(e);
                    }}
                  >
                    <div class="row custom-row">
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">First Name</label>
                        <input
                        required
                          type="text"
                          class="form-control"
                          value={formData.firstName}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          id="email"
                          name="firstName"
                        />
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Last Name</label>
                        <input
                        required
                          type="text"
                          class="form-control"
                          value={formData.lastName}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          id="lastName"
                          name="lastName"
                        />
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Phone Number</label>
                        <input
                        required
                          type="text"
                          class="form-control"
                          value={formData.phoneNumber}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          id="phoneNumber"
                          name="phoneNumber"
                        />
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Email address</label>
                        <input
                        required
                          type="email"
                          class="form-control"
                          value={formData.email}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          id="email"
                          name="email"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Password</label>
                        <input
                        required
                          type="password"
                          class="form-control"
                          value={formData.password}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          id="pass"
                          name="password"
                        />
                      </div>




                      <div class="col-12 mb-3">
                        <label class="form-label">Gender</label>
                        <select
                          name="gender"
                          id=""
                          value={formData.gender}
                          required
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          class="form-control"
                          
                        >
                          <option value="" disabled>select Gender</option>
                          {OPTIONS.genders
                            .getAllOptions()
                            .map((data) => (
                              <option value={data}>{data}</option>
                            ))}
                         
                        </select>
                      </div>

                      <div class="col-12 mb-3">
                        <label class="form-label">Account for</label>
                        <select
                          name="behalfOf"
                          id=""
                          value={formData.behalfOf}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          class="form-control"
                          required
                        >
                           <option value="" disabled>select Account </option>
                          {OPTIONS.accountCreatedFor
                            .getAllOptions()
                            .map((data) => (
                              <option value={data}>{data}</option>
                            ))}
                          
                        </select>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-12 text-center my-4">
                          <LoadingButton
                            loading={loading}
                            size="medium"
                            type="submit"
                            variant="contained"
                            startIcon={<LoginOutlined sx={{ fontSize: 1 }} />}
                          >
                            Save And Next
                          </LoadingButton>
                        </div>

                        <div class="col-12 text-center my-4">
                          <LoadingButton
                            sx={{ color: "black", bgcolor: "#e6e6e6" }}
                            onClick={() => {
                              navigate("/");
                            }}
                          
                            size="medium"
                            type="submit"
                            variant="contained"

                            // startIcon={<LoginOutlined sx={{ fontSize: 1 }} />}
                          >
                            Back to Login
                          </LoadingButton>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
