const environment = {
    // endPoint: "https://dealler-backend.onrender.com/api/v1/admin",
    //  socketEndPoint:"https://dealler-backend.onrender.com",

    //  socketEndPoint:"http://65.2.45.228:8000",
    // endPoint: "http://65.2.45.228:8000/api/v1/admin",

    // socketEndPoint:"http://localhost:8000",
    // endPoint: "http://localhost:8000/api/v1/admin",


    // socketEndPoint:"http://localhost:8000",
    //  endPoint: "http://localhost:8000/api/v1/website",



    socketEndPoint:"https://matrimonial-client-1.onrender.com",
     endPoint: "https://matrimonial-client-1.onrender.com/api/v1/website",


    // socketEndPoint:"https://shadi.njssolutions.in",
    //  endPoint: "https://shadi.njssolutions.in/api/v1/website",
   
  };

  export default environment;


