import React, { useEffect, useState } from "react";
import PersonalDetailsCard from "../components/profileCard/personalCard";
import EducationCareerCard from "../components/profileCard/educationAndCarrierCard";
import FamilyInfoCard from "../components/profileCard/familyCards";
import PartnerPreferencesCard from "../components/profileCard/partnerPreferenceCard";
import MessageSidebar from "../components/profileCard/messageSideBar";
import RightMessage from "../components/profileCard/RightMessage";
import ResetPasswordCard from "../components/profileCard/passwordReset";
import UploadCard from "../components/profileCard/uploadsCard";
import { Height } from "@mui/icons-material";
import { Box } from "@mui/material";
import ProfileService from "./profileService";
import { useDispatch } from "react-redux";
import {
  updateUserProfile,
  setUserProfile,
} from "../../Component/redux/myProfileSlice";
import UserProfileService from "../../services/Profile.services";
const Profile = () => {
  async function fetchuserProfile() {
    const data = await ProfileService.getMyProfile();
    console.log("data", data.data);
    dispatch(setUserProfile(data.data));
  }

  const dispatch = useDispatch();
  const [profile, setProfile] = useState({});

  async function fetchuserProfile2() {
    const data = await UserProfileService.getOtherProfileById(
      {},
      JSON.parse(localStorage.getItem("user"))._id
    );
    console.log("****profile DATATATA****", data.data);
    setProfile(data.data);
  }

  useEffect(() => {
    fetchuserProfile();
    fetchuserProfile2();
  }, []);

  return (
    <Box sx={{ height: "100%", overflowY: "auto" }}>
      <PersonalDetailsCard data={profile.BasicInfo} otherData={profile} />

      <EducationCareerCard />

      <FamilyInfoCard />

      <PartnerPreferencesCard />
    </Box>
  );
};

export default Profile;
