import React from 'react';
import { Container, Card, CardContent, Typography, Box } from '@mui/material';

const SupportPage = () => {
  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <Card 
        sx={{
          maxWidth: 900,
          boxShadow: 3,
          p: 3,
          borderRadius: 2,
          backgroundColor: 'white',
          border: '1px solid #ddd',
        }}
      >
        <CardContent>
          <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>
            Support & Help
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            We understand that finding the right life partner is an important and meaningful journey. Our team is dedicated to making your experience as smooth, secure, and enjoyable as possible.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            If you ever need assistance or have questions about using our platform, don’t hesitate to reach out. Whether you’re experiencing technical difficulties, need guidance with your profile, or simply want tips on how to make the most of our services, we’re here for you.
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>How to Contact Us:</Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              <strong>Email Support:</strong> Reach out to us at <strong>goswamiyugal.com@gmail.com</strong> for personalized assistance from our support team. We strive to respond within 24 hours, ensuring your needs are met promptly.
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              <strong>Phone Support:</strong> Speak directly with our friendly support team by calling <strong>1234567895</strong>. Our phone lines are open from <strong>9 AM to 6 PM (local time)</strong> to help answer any questions or resolve any concerns you may have.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SupportPage;
