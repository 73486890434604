import React, { useCallback, useEffect, useState } from "react";
import "./Login.css";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { LoginOutlined } from "@mui/icons-material";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addCurrentUser } from "../../Component/redux/astroSlice";

import { useSocket } from "../../useSocket.js/socket";
import environment from "../../enviroment";
import { toast } from "react-toastify";

import getLocation from "../../utils/Location.Tracker";
import OPTIONS from "../../constant/Option";
import UserProfileService from "../../services/Profile.services";


const Login = () => {
  const [formData, setFormData] = useState({
    userId:"6771a7d1d9569cc613d2651a",
    maritalStatus:"",
    DOB:"",
    motherTongue:"",
    height:"",
    religion:"",
    disable:false,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    setFormData({ ...formData, [name]: value });
  }

  async function HandlSubmit(e) {
    try {
      e.preventDefault();
      console.log("called the submit function");
      setLoading(true);
     

      const response=await UserProfileService.createBasicDetail(formData)
     
      if(response.data){
        navigate("/register/educationDetail");
      }
      setLoading(false);

    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <div className="p-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 loginleft d-none d-md-flex">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <div class="loginlogo">
                  <img src={logo} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 align-self-start">
            <div class="row justify-content-start">
              <div class="col-lg-11">
                <div class="loginright">
                  <h3>Enter Basic Details</h3>
                  <form
                    class="pt-2"
                    onSubmit={(e) => {
                      HandlSubmit(e);
                    }}
                  >
                    <div class="row custom-row">
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Date of Birth</label>
                        <input
                          type="date"
                          class="form-control"
                          value={formData.DOB}
                          onChange={(e) => handleChange(e)}
                          name="DOB"
                          required
                        />
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Marital Status</label>
                        <select
                          class="form-control"
                          value={formData.maritalStatus}
                          onChange={(e) => handleChange(e)}
                          name="maritalStatus"
                          required
                        >
                          {" "}
                          <option value="" disabled>
                            Select marital status
                          </option>
                          {OPTIONS.maritalStatus.getAllOptions().map((data) => (
                            <option value={data}>{data}</option>
                          ))}
                          <option value="Married">Married</option>
                        </select>
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Religion</label>
                        <select
                          class="form-control"
                          value={formData.religion}
                          onChange={(e) => handleChange(e)}
                          name="religion"
                          required
                        >
                          <option value="" disabled>
                            Select Religion
                          </option>

                          {OPTIONS.religionOptions
                            .getAllOptions()
                            .map((data) => (
                              <option value={data}>{data}</option>
                            ))}
                        </select>
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Mother Tongue</label>
                        <select
                          class="form-control"
                          value={formData.motherTongue}
                          onChange={(e) => handleChange(e)}
                          name="motherTongue"
                          required
                        >
                             <option value="" disabled>
                            Select Mother Tongue
                          </option>
                          {OPTIONS.motherTongueOptions
                            .getAllOptions()
                            .map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Height</label>
                        <select
                          class="form-control"
                          value={formData.height}
                          onChange={(e) => handleChange(e)}
                          name="height"
                          required
                        >
                          <option value="">Select height</option>
                          {OPTIONS.heightOptions
                            .getAllOptions()
                            .map((option) => (
                              <option value={option}>{option}</option>
                            ))}
                        </select>
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Disability</label>
                        <select
                          class="form-control"
                          value={formData.disable}
                          onChange={(e) => handleChange(e)}
                          name="disable"
                          required
                        >
                          <option value="">Select Disability </option>
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-12 text-center my-4">
                          <LoadingButton
                            loading={loading}
                            size="medium"
                            type="submit"
                            variant="contained"
                            startIcon={<LoginOutlined sx={{ fontSize: 1 }} />}
                          >
                            Save And Next
                          </LoadingButton>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
