import React, { useCallback, useEffect, useState } from "react";
import "./Login.css";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { LoginOutlined } from "@mui/icons-material";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
// import axiosInstance from "../../utils/requestInstance";
// import requuestInstance from "../.../../../utils/requestInstance"
import axiosInstance from "../../utils/requestInstance";
import UserProfileService from "../../services/Profile.services";

import environment from "../../enviroment";
import { toast } from "react-toastify";

import OPTIONS from "../../constant/Option";

const Login = () => {
  const [formData, setFormData] = useState({
    userId: "",
    familyType: "", // select
    familyStatus: "", // select
    nativeState: "", // select
    nativeCity: "", // text
    fatherName: "", // text
    fatherOccupation: "", // select
    motherOccupation: "", // select
    numberOfBrother: "", // number
    numberOfMarriedBrother: "", // number
    numberOfSister: "", // number
    numberOfMarriedsister: "", // number
    aboutFamily: "", // text
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    setFormData({ ...formData, [name]: value });
  }

  async function HandlSubmit(e) {
    try {
      e.preventDefault();

      setLoading(true);
     

    const response=  await UserProfileService.createFamilyDetail(formData);
    console.log("your reponse in detai",response.data)
   if(response.data){
    navigate("/");
   }
    setLoading(false);
     
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <div className="p-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 loginleft d-none d-md-flex">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <div class="loginlogo">
                  <img src={logo} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 align-self-start">
            <div class="row justify-content-start">
              <div class="col-lg-11">
                <div class="loginright">
                  <h3>Enter Family Details</h3>
                  <form
                    class="pt-2"
                    onSubmit={(e) => {
                      HandlSubmit(e);
                    }}
                  >
                    <div class="row custom-row">
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Family Type</label>
                        <select
                          class="form-control"
                          value={formData.familyType}
                          onChange={(e) => handleChange(e)}
                          name="familyType"
                          required
                        >
                          <option value="" disabled>
                            Select Family Type
                          </option>
                          {OPTIONS.familyTypeOptions
                            .getAllOptions()
                            .map((option) => (
                              <option value={option}>{option}</option>
                            ))}
                        </select>
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Family Status</label>
                        <select
                          class="form-control"
                          value={formData.familyStatus}
                          onChange={(e) => handleChange(e)}
                          name="familyStatus"
                          required
                        >
                          <option value="" disabled>
                            Select Family Status
                          </option>
                          {OPTIONS.familyStatusOptions
                            .getAllOptions()
                            .map((option) => (
                              <option value={option}>{option}</option>
                            ))}
                        </select>
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Native State</label>
                        <select
                          class="form-control"
                          value={formData.nativeState}
                          onChange={(e) => handleChange(e)}
                          name="nativeState"
                          required
                        >
                          <option value="" disabled>
                            Select Native State
                          </option>
                          {OPTIONS.state.getAllOptions().map((option) => (
                            <option value={option}>{option}</option>
                          ))}
                        </select>
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Native City</label>
                        <input
                          type="text"
                          class="form-control"
                          value={formData.nativeCity}
                          onChange={(e) => handleChange(e)}
                          name="nativeCity"
                          required
                        />
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Father's Name</label>
                        <input
                          type="text"
                          class="form-control"
                          value={formData.fatherName}
                          onChange={(e) => handleChange(e)}
                          name="fatherName"
                          required
                        />
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Father's Occupation</label>
                        <select
                          class="form-control"
                          value={formData.fatherOccupation}
                          onChange={(e) => handleChange(e)}
                          name="fatherOccupation"
                          required
                        >
                          <option value="" disabled>
                            Select Occupation
                          </option>
                          {OPTIONS.fatherOccupation
                            .getAllOptions()
                            .map((option) => (
                              <option value={option}>{option}</option>
                            ))}
                        </select>
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Mother's Occupation</label>
                        <select
                          class="form-control"
                          value={formData.motherOccupation}
                          onChange={(e) => handleChange(e)}
                          name="motherOccupation"
                          required
                        >
                          <option value="" disabled>
                            Select Occupation
                          </option>
                          {OPTIONS.motherOcupation
                            .getAllOptions()
                            .map((option) => (
                              <option value={option}>{option}</option>
                            ))}
                        </select>
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Number of Brothers</label>
                        <input
                          type="number"
                          class="form-control"
                          value={formData.numberOfBrother}
                          onChange={(e) => handleChange(e)}
                          name="numberOfBrother"
                          required
                        />
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">
                          Number of Married Brothers
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          value={formData.numberOfMarriedBrother}
                          onChange={(e) => handleChange(e)}
                          name="numberOfMarriedBrother"
                          required
                        />
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">Number of Sisters</label>
                        <input
                          type="number"
                          class="form-control"
                          value={formData.numberOfSister}
                          onChange={(e) => handleChange(e)}
                          name="numberOfSister"
                          required
                        />
                      </div>
                      <div class="col-12 col-sm-6 mb-3">
                        <label class="form-label">
                          Number of Married Sisters
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          value={formData.numberOfMarriedsister}
                          onChange={(e) => handleChange(e)}
                          name="numberOfMarriedsister"
                          required
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">About Family</label>
                        <textarea
                          class="form-control"
                          value={formData.aboutFamily}
                          onChange={(e) => handleChange(e)}
                          name="aboutFamily"
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-12 text-center my-4">
                          <LoadingButton
                            loading={loading}
                            size="medium"
                            type="submit"
                            variant="contained"
                            startIcon={<LoginOutlined sx={{ fontSize: 1 }} />}
                          >
                            Save And Next
                          </LoadingButton>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
