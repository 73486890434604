import * as React from "react";
import Box from "@mui/material/Box";
import { Navigate } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AlignVerticalCenterTwoToneIcon from "@mui/icons-material/AlignVerticalCenterTwoTone";
import SettingsIcon from '@mui/icons-material/Settings';

import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";

import logo from "../../assets/images/logo.png";
import { useSelector } from "react-redux";
import { useState } from "react";




import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from '@mui/icons-material/Person';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import SettingsPowerOutlinedIcon from '@mui/icons-material/SettingsPowerOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAdd';



const DrawerBox = (props) => {
  console.log("your props", props);

  const [open, setOpen] = React.useState(false);

  const[profile,setProfile]=useState(false);
  const[myactivity,setMyactivity]=useState(false);
  const[otherActivity,setOtherActivity]=useState(false);
  const[payment,setPayment]=useState(false);
  const[accountSetting,setAccountSetting]=useState(false);


  const currentUser = useSelector((state) => state.currentUser);


  const handleClick = () => {
    setOpen(!open);
  };

  const navigate = useNavigate();

  const handleClickRoute = (e) => {
    navigate("/user");
  };
  return (
    <div >
      <Box
        sx={{ width: "100%", minWidth: { xs: "330px", md: "180px" } }}
        role="presentation"
      >
        <img
          className="d-lg-none"
          src={logo}
          alt="logo"
          style={{ height: "68px", marginRight: "15px" }}
        />
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton
            color="primary"
            sx={{ color: "green", fontWeight: 600 }}
            onClick={handleClick}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary="HOME"
              primaryTypographyProps={{ fontWeight: "bold" }}
              onClick={(e) => {
                navigate("/dashboard/home");
              }}
            />
          </ListItemButton>

          <ListItemButton
            color="primary"
            sx={{ color: "green" }}
            onClick={(e) => setProfile(!profile)}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary="PROFILE"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
            {profile ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={profile} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} onClick={(e) => {
                    navigate("/dashboard/myProfile");
                  }}>
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="VIEW MY PROFILE"
                 
                />
              </ListItemButton>

              {/* <ListItemButton
                sx={{ pl: 4, boxShadow: "" }}
                onClick={(e) => {
                  navigate("/dashboard/product/create");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="EDIT MY PROFILE" />
              </ListItemButton> */}

              {/* <ListItemButton
                sx={{ pl: 4, boxShadow: "" }}
                onClick={(e) => {
                  navigate("/dashboard/trustBadge");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="ADD TRUST BADGE" />
              </ListItemButton> */}
            </List>
          </Collapse>

          <ListItemButton
            color="primary"
            sx={{ color: "green" }}
            onClick={(e) => setMyactivity(!myactivity)}
          >
            <ListItemIcon>
            <PeopleAltOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="MY ACTIVITY"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
            {myactivity ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={myactivity} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <AlignVerticalCenterTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="PROFILE VIEWED BY ME"
                      onClick={(e) => {
                        navigate("/dashboard/myActivity");
                      }}
                    />
                  </ListItemButton>

                  {/* <ListItemButton
                    sx={{ pl: 4, boxShadow: "" }}
                    onClick={(e) => {
                      navigate("/dashboard/myActivity/contactView");
                    }}
                  >
                    <ListItemIcon>
                      <AlignVerticalCenterTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary="CONTACT VIEWED BY ME"
                    
                    />
                  </ListItemButton> */}


              <ListItemButton
                sx={{ pl: 4, boxShadow: "" }}
                onClick={(e) => {
                  navigate("/dashboard/myActivity/shortlist");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="PROFILE SHORTLISTED BY ME"  />
              </ListItemButton>


              <ListItemButton
                sx={{ pl: 4, boxShadow: "" }}
                onClick={(e) => {
                  navigate("/dashboard/myActivity/interest");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="MY INTEREST"
                
               />
              </ListItemButton>



              
              <ListItemButton
                sx={{ pl: 4, boxShadow: "" }}
                onClick={(e) => {
                  navigate("/dashboard/myActivity/ignore");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="PROFILE IGNORE BY ME"  />
              </ListItemButton>

            </List>




          </Collapse>

          <ListItemButton
            color="primary"
            sx={{ color: "green" }}
            onClick={(e) => setOtherActivity(!otherActivity)}
          >
            <ListItemIcon>
            <GroupAddIcon />
            </ListItemIcon>
            <ListItemText
              primary="OTHERS ACTIVITY"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
            {otherActivity ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={otherActivity} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={(e) => {
                  navigate("/dashboard/otherActivity");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                                <ListItemText primary="WHO VIEW MY PROFILE"  />
              </ListItemButton>


              {/* <ListItemButton
                sx={{ pl: 4 }}
                onClick={(e) => {
                  navigate("/dashboard/otherActivity/contactView");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="WHO VIEW MY CONTACT" />
              </ListItemButton> */}

              <ListItemButton
                sx={{ pl: 4 }}
                onClick={(e) => {
                  navigate("/dashboard/otherActivity/galleryRequest");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="WHO REQUEST FOR GALLERY" />
              </ListItemButton>


              <ListItemButton
                sx={{ pl: 4 }}
                onClick={(e) => {
                  navigate("/dashboard/otherActivity/interest");
                }}>
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="WHO SHOWS INTEREST ON ME" />
              </ListItemButton>

            </List>

            
          </Collapse>

          <ListItemButton
            color="primary"
            sx={{ color: "green" }}
            onClick={(e) => {
              navigate("/dashboard/message");
            }}
          >
            <ListItemIcon>
           
            <MessageOutlinedIcon/>
            </ListItemIcon>
            <ListItemText
              primary="MESSAGE"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
         
          </ListItemButton>
        

          <ListItemButton
            color="primary"
            sx={{ color: "green" }}
            onClick={(e) => setPayment(!payment)}
          >
            <ListItemIcon>
              <LocalAtmOutlinedIcon />

            </ListItemIcon>
            <ListItemText
              primary="PAYMENT"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
            {payment ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={payment} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={(e) => {
                  navigate("/dashboard/payment");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="MY PACKAGE" />
              </ListItemButton>


              <ListItemButton
                sx={{ pl: 4 }}
                onClick={(e) => {
                  navigate("/dashboard/mypackage");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="MY PAYMENTS" />
              </ListItemButton>
            </List>
          </Collapse>




          <ListItemButton
            color="primary"
            sx={{ color: "green" }}
        
          >
            <ListItemIcon>
              <RocketLaunchOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="GO PREMIUM"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
          </ListItemButton>

          <ListItemButton
            color="primary"
            sx={{ color: "green" }}

            onClick={(e) => {
              navigate("/dashboard/uploadAndAccess");
            }}
         
          >
            <ListItemIcon>
              <AddAPhotoOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="UPLOAD & ACCESS"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
          </ListItemButton>




          
          <ListItemButton
            color="primary"
            sx={{ color: "green" }}
            onClick={(e) => setAccountSetting(!accountSetting)}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary="ACCOUNT SETTINGS"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
              {accountSetting ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={accountSetting} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={(e) => {
                  navigate("/dashboard/accountSetting");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="PASSWORD & NOTIFICATION" />
              </ListItemButton>



              
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={(e) => {
                  navigate("/dashboard/invoice");
                }}
              >
                <ListItemIcon>
                  <AlignVerticalCenterTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="FILTER SETTING" />
              </ListItemButton>
            </List>
          </Collapse>





          
          <ListItemButton
            color="primary"
            sx={{ color: "green" }}

            onClick={(e) => {
              navigate("/dashboard/help");
            }}
          >
            <ListItemIcon>
              <HelpOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="HELP"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
          </ListItemButton>





          
          <ListItemButton
            color="primary"
            sx={{ color: "green" }}
          
          >
            <ListItemIcon>
            <SettingsPowerOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="LOG OUT"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
          </ListItemButton>
        
        </List>
      </Box>
    </div>
  );
};

export default DrawerBox;
