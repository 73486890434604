import React, { useCallback, useState } from 'react';
import { Box, Avatar, Typography, IconButton,Grid, Paper, TextField, Button } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect,useRef } from 'react';

import { useParams,useSearchParams } from 'react-router-dom';
import UserProfileService from '../../../services/Profile.services';
import { useSelector } from 'react-redux';
import { useSocket } from '../../../useSocket.js/socket';
import { toast } from 'react-toastify';
import logo from "../../../assets/images/logo.png"

const formatTime = (isoDate) => {
  const date = new Date(isoDate);
  const formatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return formatter.format(date);
};




function ChatMessage({ sender, message, isOwnMessage, timestamp }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isOwnMessage ? 'row-reverse' : 'row',
        alignItems: 'center',
        mb: 2,
      }}
    >
      
      <Paper
        sx={{
          p: 1.5,
          maxWidth:{xs:'85%',md:'70%',lg:"50%"},
          bgcolor: isOwnMessage ? 'primary.main' : 'grey.300',
          color: isOwnMessage ? 'white' : 'black',
          borderRadius: 2,
        }}
      >
        <Typography variant="body2">{message}</Typography>
        <Typography variant="caption" sx={{ display: 'block', textAlign: isOwnMessage ? 'right' : 'left' }}>
          {timestamp}
        </Typography>
      </Paper>
    </Box>
  );
}

function ChatApp({isSideBar,setIsSideBar,recentChat}) {

  const [searchParams] = useSearchParams();
  const [chatPartner,setChatPartner]=useState({})
  const [messages,setMessages]=useState([])
  const currentUser = useSelector((state) => state.astro.currentUser);
  const [userId,setUserId]=useState("")
  const messageContainerRef = useRef(null);
  const socket=useSocket()

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages]);




  async function  FetchMessage(userId,chatId){
    const data= await UserProfileService.getAllmessage(userId,chatId);
    let MessageArray=data.data

    console.log("*****current user************",currentUser)
    
    const FinalArray=MessageArray.map((message)=>{

     console.log("checking ",message.sender)
     console.log("reciver ",message.receiver)

      return  {
        ...message,
        isOwnMessage:currentUser._id !== message.receiver,
        timestamp:formatTime(message.createdAt)
      }
    })

    setMessages(FinalArray)
  }

  useEffect(() => {
    // Extract chatId and userId from search params
    const chatId = searchParams.get("chatId");
    const userId = searchParams.get("userId");
    setUserId(userId);
    if (chatId || userId) {
      // Make API call whenever chatId or userId changes
    let chatPartner=recentChat.find((user)=>user._id==userId);
    setMesssage("");
    setChatPartner(chatPartner);
    FetchMessage( userId,chatId);
    }
  }, [searchParams,recentChat]); 





  // Working with Chatting Logic 

  const [message,setMesssage]=useState("");

  

   async function SendMessage(){
    const userId = searchParams.get("userId");
    const newMessage={
      content:message,
      sender:currentUser._id,
      receiver:userId,
      createdAt:new Date(),
      isOwnMessage:currentUser._id !== userId,
      timestamp:formatTime(new Date())

    }

    socket.emit("send:message",{
      content:message,
      sender:currentUser._id,
      receiver:userId,
    })
    UserProfileService.sendMessage(userId,{content:message})

  setMessages([...messages,newMessage])
    setMesssage("")
  
  }




 
  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      console.log('Enter key pressed');
      // Call your callback function here
      SendMessage();
    }
  };


  const sendMessage=useCallback(({sender,receiver,content,createdAt})=>{


function appendMessage(data){
setMessages([...messages,data])
}

console.log("your userId",userId,"senderId",sender,"Checking",userId===sender)
if(userId==sender){
  let newMessage={
    content:content,
    sender:sender,
    receiver:receiver,
    isOwnMessage:currentUser._id == sender,
    timestamp:formatTime(createdAt)
  }
  setMessages((prevMessages) => [...prevMessages, newMessage]);
}
   
  },[socket,userId])






  useEffect(()=>{
      socket.on("send:message",sendMessage)
      return ()=>{
        socket.off("send:message")
      }
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxWidth: '100%',
        mx: 'auto',
        bgcolor: 'background.default',
        border: '1px solid #ddd',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          borderBottom: '1px solid #ddd',
        }}
      >
<Grid container sx={{ display: "flex", alignItems: "center" }}>
  {/* Back Button */}
{
  !isSideBar?<>
    <Grid item>
    <IconButton onClick={() => {setIsSideBar(true)}}>
      <ArrowBackIcon />
    </IconButton>
  </Grid>
  </>:null
}

  {/* User Info Section */}
  <Grid item sx={{ display: "flex", alignItems: "center", ml: 1 }}>
    <Avatar
      src={!userId?logo: chatPartner?.profileImage}
      sx={{ width: 40, height: 40, mr: 2 }}
    />
    <Box>
      <Typography variant="subtitle1">{ !userId?"Wellcome To Goswami Yugal"  :  chatPartner?.firstName +" "+chatPartner?.lastName}</Typography>
      <Typography variant="caption" color="textSecondary">
        Online
      </Typography>
    </Box>
  </Grid>
</Grid>
        
        
      </Box>

      {/* Chat Messages */}
      <Box sx={{ flexGrow: 1, p: 2, overflowY: 'auto' }}   ref={messageContainerRef} >
      {
  messages.length > 0 ? (
    messages.map((eachMessage, index) => (
      <ChatMessage
        key={index}
        sender="Aris"
        message={eachMessage.content}
        isOwnMessage={eachMessage.isOwnMessage}
        timestamp={eachMessage.timestamp}
      />
    ))
  ) : (
    <div style={{ textAlign: "center", padding: "20px", color: "#555", fontStyle: "italic" }}>
      <h2 style={{ color: "#ff4081" }}>💌 Every great relationship starts with a conversation 💌</h2>
      <p>
        Take the first step. Select a chat and let your story begin!
      </p>
    </div>
  )
}




      </Box>

      {/* Message Input */}
      <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Distribute items evenly with space between them
   
    py:1,
    borderTop: '1px solid #ddd',
    width: "100%",
   
  }}
>
  <IconButton>
    <AttachFileIcon />
  </IconButton>
  <IconButton>
    <InsertEmoticonIcon />
  </IconButton>
  <TextField
    fullWidth
    value={message}
    onChange={e=>setMesssage(e.target.value)}
    placeholder="Type a message here"
    onKeyDown={handleEnterPress}
    variant="outlined"
    size="medium"
    sx={{
      '& .MuiOutlinedInput-root': {
        borderRadius: '25px',
        // Adjust padding for better visual appearance
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ccc',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#1976d2',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#1976d2',
      },
    }}
  />
  <IconButton
    color="primary"
    onClick={e=>SendMessage()}
    sx={{
      padding: 1.7,
      marginLeft:1,
      borderRadius: '50%',
      backgroundColor: 'primary.main',
      '&:hover': {
        backgroundColor: 'primary.dark',
      },
      backgroundColor: 'primary.dark',
    }}
  >
    <SendIcon sx={{ color: 'white' }} />
  </IconButton>
</Box>

    </Box>
  );
}

export default ChatApp;
