import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
// import SideBar from "./components/Sidebar/SideBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import environment from "./enviroment";
import Index from "./pages/Router/Index";
// import UserView from "./pages/user/userview/UserView";
// import UserList from "./pages/user/userList";

// import OrderDetails from "./pages/order/details/detail";
import "react-toastify/dist/ReactToastify.css";

//IMPORT USER  ROUTES COMPONENT
// import User from "./parentPages/User";

// import UserEdit from "./pages/user/edit/edit";
// import UserCreate from "./pages/user/create/create";

// IMPORT PRODUCT ROUTES COMPONENT
// import Product from "./parentPages/Product";
// import ProductList from "./pages/product/list/list";
// import ProductEdit from "./pages/product/edit/edit";
// import Items from "./pages/product/items/items";
// import ProductDetail from './pages/product/detail/detail'
// import ProductCreate from "./pages/product/create/create";

// IMPORT ORDER ROUTES COMPONENT

// import Order from "./parentPages/order";
// import OrderList from "./pages/order/list/list";
// import OrderDetails from "./pages/order/details/detail";

//IMPORTING PAYMENT ROUTES COMPONENT
// import Payment from "./parentPages/payment";
// import PaymentCreate from "./pages/payment/create/create";
// import PaymentList from "./pages/payment/list/list";
// import PaymentEdit from "./pages/payment/edit/edit";

//IMPORTING INVOICE ROUTES COMPOENT
// import Invoice from "./parentPages/invoice";
// import InvoiceList from "./pages/invoice/list/list";
// import InvoiceDetail from "./pages/invoice/detail/detail";

//IMPORTING LOGIN COMPONENT
import Login from "./Component/login/Login";

//IMPORTING VISIT COMPONENT

// import Visit from "./parentPages/Visit";
// import VisitCreate from "./pages/visit/create/create";
// import VisitEdit from "./pages/visit/edit/edit";
// import VisitList from "./pages/visit/list/list";
// import VisitView from "./pages/visit/ViewVisit/view";
// import LiveLocation from "./pages/visit/liveLocation/view"

import PrivateRoute from "./parentPages/privateRoute";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCurrentUser } from "./Component/redux/astroSlice";

//IMPORTING SERVICES

//***********************************NEW WORKING FROM HERE ***************************** */

//PROFILE

import ParentProfile from "./parentPage2/profile";
import Profile from "./pages2/Profile/profile";

//HOME

import ParentHome from "./parentPage2/home";
import Home from "./pages2/Home/home";

// MYACTIVITY
import ParentMyActivity from "./parentPage2/myActivity";
import ProfileByview from "./pages2/MyActivity/profileView";
import ContactView from "./pages2/MyActivity/contactView";
import MyInterest from "./pages2/MyActivity/myInterest";
import ProfileShortlisted from "./pages2/MyActivity/ProfileShortlist";
import ProfileIgnore from "./pages2/MyActivity/profileIgnore";

//OTHER ACTIVITY

import ParentOtherActivity from "./parentPage2/otherActivity";
import OtherProfileView from "./pages2/OthersAcitivity/profileView";
import OtherContactView from "./pages2/OthersAcitivity/contactView";
import ShowInterest from "./pages2/OthersAcitivity/showsInterest";

//MESSAGE

import ParentMessage from "./parentPage2/message";
import Message from "./pages2/Message/message";

//PAYMENT

import ParentPayment from "./parentPage2/payment";
import MYPackage from "./pages2/Payment/myPackage";
import Mypayment from "./pages2/Payment/myPayment";

//PREMIUM
import ParentPremium from "./parentPage2/premium";
import Premium from "./pages2/GoPremium/premium";

//UPLOAD AND ACCESS
import ParentUploadAndAccess from "./parentPage2/uploadAndAccess";
import UploadAndAccess from "./pages2/Upload&Access/uploadAndAccess";

//ACCOUNT SETTINGS

import ParentAccountSetting from "./parentPage2/accountSetting";
import PasswordAndNotification from "./pages2/AccountSetting/passwordAndNotification";
import FilterSetting from "./pages2/AccountSetting/filterSetting";

//HELP
import ParentHelp from "./parentPage2/help";
import Help from "./pages2/Help/help";

//Trust Badge
import TrustBadge from "./pages2/Profile/trustBadge";

//Other profile

import OtherProfile from "./pages2/otherProfile/profile";



// User on Boarding 
import PrimaryDetail from "./pages2/userOnboarding/primary"
import BasicDetail from "./pages2/userOnboarding/Basic"
import CarrierDetail from './pages2/userOnboarding/Carrier'
import FamilyDetail from "./pages2/userOnboarding/family"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />, // Default component for /
  },

  {
    path: "/register/primaryDetail",
    element: <PrimaryDetail />, // Default component for /
  },
  {
    path: "/register/basicDetail",
    element: <BasicDetail />, // Default component for /
  },
  {
    path: "/register/educationDetail",
    element: <CarrierDetail />, // Default component for /
  },
  {
    path: "/register/familydetail",
    element: <FamilyDetail />, // Default component for /
  },

  {
    path: "/dashboard",
    element: <Index />,

    children: [
      // {
      //   path: "user",

      //   element: (
      //     <PrivateRoute>
      //       {" "}
      //       <User />
      //     </PrivateRoute>
      //   ), // Parent component for user routes, it should contain <Outlet />
      //   children: [
      //     {
      //       index: true,
      //       element: <UserList />, // Default component for /user
      //     },
      //     {
      //       path: "create",
      //       element: <UserCreate />,
      //     },
      //     {
      //       path: "edit/:id",
      //       element: <UserEdit />,
      //     },
      //   ],
      // },

      // {
      //   path: "product",
      //   element: (
      //     <PrivateRoute>
      //       {" "}
      //       <Product />
      //     </PrivateRoute>
      //   ), // Parent component for user routes, it should contain <Outlet />
      //   children: [
      //     {
      //       index: true,
      //       element: <ProductList />, // Default component for /user
      //     },
      //     {
      //       path: "create",
      //       element: <ProductCreate />,
      //     },
      //     {
      //       path: "edit/:id",
      //       element: <ProductEdit />,
      //     },
      //     {
      //       path: "items",
      //       element: <Items />,
      //     },
      //   ],
      // },
      // {
      //   path: "order",
      //   element: <Product />, // Parent component for user routes, it should contain <Outlet />
      //   children: [
      //     {
      //       index: true,
      //       element: <OrderList />, // Default component for /user
      //     },
      //     {
      //       path: "detail/:id",
      //       element: <OrderDetails />,
      //     },
      //   ],
      // },

      // {
      //   path: "payment",
      //   element: (
      //     <PrivateRoute>
      //       {" "}
      //       <Payment />
      //     </PrivateRoute>
      //   ),
      //     // Parent component for user routes, it should contain <Outlet />
      //   children: [
      //     {
      //       index: true,
      //       element: <PaymentList />, // Default component for /user
      //     },
      //     {
      //       path: "create/:id",
      //       element: <PaymentCreate />,
      //     },
      //     {
      //       path: "edit/:id",
      //       element: <PaymentEdit />,
      //     },
      //   ],
      // },

      // {
      //   path: "invoice",
      //   element: (
      //     <PrivateRoute>
      //       {" "}
      //       <Invoice />
      //     </PrivateRoute>
      //   ),
      //        // Parent component for user routes, it should contain <Outlet />
      //   children: [
      //     {
      //       index: true,
      //       element: <InvoiceList />, // Default component for /user
      //     },
      //     {
      //       path: "detail/:id",
      //       element: <InvoiceDetail />,
      //     },
      //   ],
      // },

      // {
      //   path: "visit", // Parent component for user routes, it should contain <Outlet />
      //   element: (
      //     <PrivateRoute>
      //       {" "}
      //       <Visit />
      //     </PrivateRoute>
      //   ),
      //   children: [
      //     {
      //       index: true,
      //       element: <VisitList />, // Default component for /user
      //     },
      //     {
      //       path: "create",
      //       element: <VisitCreate />,
      //     },
      //     {
      //       path: "edit/:id",
      //       element: <VisitEdit />,
      //     },

      //     {
      //       path: "view",
      //       element: <VisitView />,
      //     },
      //     {
      //       path: "liveLocation",
      //       element: <LiveLocation />,
      //     },
      //   ],
      // },

      //HERE THE NEW  WORK STARTED

      {
        path: "home", // Parent component for user routes, it should contain <Outlet />
        element: (
          <PrivateRoute>
            {" "}
            <ParentHome />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <Home />, // Default component for /user
          },
        ],
      },

      {
        path: "myProfile", // Parent component for user routes, it should contain <Outlet />
        element: (
          <PrivateRoute>
            {" "}
            <ParentOtherActivity />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <Profile />, // Default component for /user
          },
        ],
      },

      {
        path: "profile/:id", // Parent component for user routes, it should contain <Outlet />
        element: (
          <PrivateRoute>
            {" "}
            <ParentOtherActivity />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <OtherProfile />, // Default component for /user
          },
        ],
      },

      {
        path: "myActivity", // Parent component for user routes, it should contain <Outlet />
        element: (
          <PrivateRoute>
            {" "}
            <ParentMyActivity />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <ProfileByview />, // Default component for /user
          },

          {
            path: "contactView",
            element: <ContactView />,
          },

          {
            path: "shortlist",
            element: <ProfileShortlisted />,
          },

          {
            path: "interest",
            element: <MyInterest />,
          },

          {
            path: "ignore",
            element: <ProfileIgnore />,
          },
        ],
      },

      {
        path: "otherActivity", // Parent component for user routes, it should contain <Outlet />
        element: (
          <PrivateRoute>
            {" "}
            <ParentOtherActivity />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <OtherProfileView />, // Default component for /user
          },

          {
            path: "contactView",
            element: <OtherContactView />,
          },

          {
            path: "interest",
            element: <ShowInterest />,
          },
        ],
      },

      {
        path: "message", // Parent component for user routes, it should contain <Outlet />
        element: (
          <PrivateRoute>
            {" "}
            <ParentMessage />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <Message />, // Default component for /user
          },
        ],
      },

      {
        path: "payment", // Parent component for user routes, it should contain <Outlet />
        element: (
          <PrivateRoute>
            {" "}
            <ParentPayment />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <Mypayment />, // Default component for /user
          },

          {
            path: "mypackage",
            element: <MYPackage />,
          },
        ],
      },

      {
        path: "accountSetting", // Parent component for user routes, it should contain <Outlet />
        element: (
          <PrivateRoute>
            {" "}
            <ParentAccountSetting />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <PasswordAndNotification />, // Default component for /user
          },

          {
            path: "filter",
            element: <FilterSetting />,
          },
        ],
      },

      {
        path: "premium", // Parent component for user routes, it should contain <Outlet />
        element: (
          <PrivateRoute>
            {" "}
            <ParentPremium />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <Premium />, // Default component for /user
          },
        ],
      },

      {
        path: "uploadAndAccess", // Parent component for user routes, it should contain <Outlet />
        element: (
          <PrivateRoute>
            {" "}
            <ParentUploadAndAccess />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <UploadAndAccess />, // Default component for /user
          },
        ],
      },

      {
        path: "help", // Parent component for user routes, it should contain <Outlet />
        element: (
          <PrivateRoute>
            {" "}
            <ParentHelp />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <Help />, // Default component for /user
          },
        ],
      },

      {
        path: "trustBadge", // Parent component for user routes, it should contain <Outlet />
        element: (
          <PrivateRoute>
            {" "}
            <ParentProfile />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <TrustBadge />, // Default component for /user
          },
        ],
      },

      {
        path: "*",
        element: <>Not Found</>,
      },
    ],
  },
]);

function App() {
  const dispatch = useDispatch();
  async function fetchMasterData() {
    const CurrentUser = JSON.parse(localStorage.getItem("user"));
    dispatch(addCurrentUser(CurrentUser));
  }

  useEffect(() => {
    fetchMasterData();
  }, []);

  useEffect(() => {
    // Function to handle tab visibility changes
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log("Tab is inactive");
        // Handle the tab becoming inactive
      } else {
        console.log("Tab is active");
        // Handle the tab becoming active
        // Your custom function
      }
    };

    // Add visibilitychange event listener
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
